<template id="star-rating">
  <div class="d-inline-block">
    <div class="star-rating" :title="calculateRating()+'%'">
      <div class="back-stars">
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>

        <div class="front-stars" :style="'width: '+calculateRating()+'%'">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="d-inline-block ml-3">{{ this.rating }} / 10</div>
  </div>
</template>

<style>
.star-rating {
  display: inline-block;
  align-items: center;
  font-size: 3em;
  justify-content: center;
}

.back-stars {
  display: flex;
  color: #bb5252;
  position: relative;
}

.back-stars > i {
  color: #bb5252 !important;
}

.front-stars {
  display: flex;
  color: #FFBC0B;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all .5s
}

.front-stars > i {
  color: #FFBC0B !important;
}
</style>

<script>

export default {
  name: 'starRating',
  components: {},
  props: {
    rating: {}
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    calculateRating() {
      return this.rating * 10;
    }
  }
};
</script>