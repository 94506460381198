<template id="score-progress-bar">
  <div>
    <label class="fw-500 fs-15px">
      {{ this.title }}
    </label>
    <span class="fw-500 fs-15px float-right">{{ this.score }}  / 10</span>
    <div class="progress">
      <div class="progress-bar bg-warning" role="progressbar"
           :style="'width:'+( this.calculateScore() )+'%'"
           :aria-valuenow="this.calculateScore()"
           aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'scoreProgressBar',
  components: {},
  props: {
    title:{},
    score: {}
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    calculateScore() {
      return this.score * 10;
    }
  }
};
</script>