<template id="review-detail">
  <div id="review-detail-modal">
    <div class="modal-backdrop"></div>
    <div class="modal">

      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">{{ $t('Review Detail', 'Review Detail') }}</h5>
            <button
                type="button"
                class="close"
                @click="close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </header>

          <section class="modal-body" v-if="this.review.id === null">
            {{ $t('Loading', 'Loading...') }}
          </section>
          <section id="printContainer" class="modal-body " v-else>
            <div class="col-12">
              <label class="d-block fw-600 fs-15px">
                {{ review.reviewer }}
                <img
                    class="h-25px w-25px rounded-sm mr-3"
                    :src="getCountryFlag(review.countryCode)"
                    v-if=" review.countryCode.trim() !== ''"
                    alt=""
                />
                <star-rating
                    :rating="review.reviewScore"
                />
              </label>
              <div class="fw-600 mb-3">{{ review.channel }} {{ renderReviewDate() }}</div>
              <div class="fs-14px">{{ review.review }}</div>
              <label class="d-block fw-600 fs-15px mt-3"
                     v-if="review.reviewEn !== null && review.reviewEn.trim() !== ''">
                {{ $t('Translated by Google') }}
              </label>
              <div class="fs-14px" v-if="review.reviewEn !== null && review.reviewEn.trim() !== ''">
                {{ review.reviewEn }}
              </div>

              <div class="row">
                <div v-if="review.reviewScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.reviewScore"
                                      :title="this.$t('Review Score', 'Review Score')"></score-progress-bar>
                </div>
                <div v-if="review.hotelScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.hotelScore"
                                      :title="this.$t('Hotel Score', 'Hotel Score')"></score-progress-bar>
                </div>
                <div v-if="review.roomsScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.roomsScore"
                                      :title="this.$t('Room Score', 'Room Score')"></score-progress-bar>
                </div>
                <div v-if="review.mealScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.mealScore"
                                      :title="this.$t('Meal Score', 'Meal Score')"></score-progress-bar>
                </div>
                <div v-if="review.staffScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.staffScore"
                                      :title="this.$t('Staff Score', 'Staff Score')"></score-progress-bar>
                </div>
                <div v-if="review.cleannessScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.cleannessScore"
                                      :title="this.$t('Cleanness Score', 'Cleanness Score')"></score-progress-bar>
                </div>
                <div v-if="review.locationScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.locationScore"
                                      :title="this.$t('Location Score', 'Location Score')"></score-progress-bar>
                </div>
                <div v-if="review.transportationScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.transportationScore"
                                      :title="this.$t('Transportation Score', 'Transportation Score')"></score-progress-bar>
                </div>
                <div v-if="review.activityScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.activityScore"
                                      :title="this.$t('Activity Score', 'Activity Score')"></score-progress-bar>
                </div>
                <div v-if="review.serviceScore != null" class="col-12 col-md-3 col-lg-4 mt-3">
                  <score-progress-bar :score="review.serviceScore"
                                      :title="this.$t('Service Score', 'Service Score')"></score-progress-bar>
                </div>
              </div>

              <div class="row" v-if="this.isExistsResponse()">
                <div class="col-12">
                  <hr class="mt-3"/>
                  <h5 class="modal-title">{{ $t('Response', 'Response') }}</h5>
                  <label class="d-block fw-600 fs-15px mt-5">
                    {{ review.response.username }} {{ renderReviewResponseDate() }}
                  </label>
                  <div class="fs-14px">
                    <span class="d-block fs-15px"
                          v-if="review.response.connectionToSubject !== null">
                      {{ review.response.connectionToSubject }}
                    </span>
                    {{ review.response.text }}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer class="modal-footer" v-if="this.review.id !== null">
            <div class="row w-100">
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <label class="col-12 col-lg-2 col-form-label pl-0 pr-0">Email:</label>
                  <div class="col-9 col-lg-7 pl-0 pr-0">
                    <input name="emailAddress"
                           id="emailAddress"
                           type="text"
                           class="form-control"
                           data-type="email"
                           required="required"
                           v-model="emailAddress"
                           :data-msg-email="$t('Please enter email address')"
                           :placeholder="$t('Enter email address')"/>
                  </div>
                  <div class="col-3 col-lg-3 pl-0 pr-0 text-right text-md-left">
                    <button type="button"
                            id="sendEmail"
                            @click="sendEmail"
                            class="btn btn-primary">
                      <i class="far fa-envelope"></i>
                      {{ $t('Send', {value: 'Send'}) }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 pl-0 pr-0 text-right">
                <button
                    type="button"
                    class="btn btn-secondary mr-3"
                    @click="close"
                >
                  <i class="fa fa-times"></i>
                  {{ $t('Close', {value: 'Close'}) }}
                </button>
                <button type="button"
                        data-print-prop-click="print"
                        data-print-param-loaded="false"
                        data-print-param-container="#printContainer"
                        class="btn btn-primary">
                  <i class="fa fa-print"></i>
                  {{ $t('Print', {value: 'Print'}) }}
                </button>

              </div>
            </div>
          </footer>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {coreJs} from "utilPath/coreJs";
import i18nService from "../../../../tools/src/core/services/i18n.service";
import StarRating from "../../content/StarRating";
import {emailUtil} from "../../../../tools/src/core/util/data-type-email";
import {_elementUtil} from "../../../../tools/src/core/util/element-util";
import {addLoader, alertMessage, objectLength, removeLoader} from "../../../../tools/src/core/util/helper";
import i18n from "../../../../tools/src/core/plugins/vue-i18n";
import ScoreProgressBar from "../../content/ScoreProgressBar";

export default {
  name: 'reviewDetail',
  components: {ScoreProgressBar, StarRating},
  props: {
    review: {}
  },
  data() {
    return {
      name: this.$t('Review Detail', {value: "Review Detail"}),
      title: this.$t('Review Detail', {value: "Review Detail"}),
      emailAddress: ''
    }
  },
  mounted() {
    coreJs.initContainer({container: '#review-detail-modal', name: this.name, title: this.title});
  },
  updated() {
    coreJs.initContainer({container: '#review-detail-modal', name: this.name, title: this.title});
  },
  methods: {
    close() {
      coreJs.close({container: '#review-detail-modal'});
      this.$emit('close');
    },
    getCountryFlag(countryCode) {
      return i18nService.getCountryFlag(countryCode);
    },
    renderReviewDate() {
      let arr = this.review.reviewDate.split(' ');
      let arrDate = arr[0].split('-');
      return arrDate[2] + '/' + arrDate[1] + '/' + arrDate[0] + ' ' + (arr[1] !== "00:00:00" ? arr[1] : "");
    },
    sendEmail() {
      if (emailUtil($('#emailAddress'), true)) {
        let _this = this;
        addLoader(document.getElementById('sendEmail'));
        _elementUtil.postService(this.$envConfig.API_URL + '/review/' + _this.review.id + '/send', {
          element: null,
          methodType: 'POST',
          dataType: 'JSON',
          data: JSON.stringify({
            email: _this.emailAddress
          }),
          callBack: function (elt, response) {
            removeLoader(document.getElementById('sendEmail'));
            alertMessage(i18n.t('Email successfully send.'));
          }
        });
      }
    },
    isExistsResponse() {
      return this.review.response !== null && objectLength(this.review.response) > 0;
    },
    renderReviewResponseDate() {
      if (this.review.response.publishedDate !== null && this.review.response.publishedDate !== "") {
        let arr = this.review.response.publishedDate.split(' ');
        let arrDate = arr[0].split('-');
        return " - " + arrDate[2] + '/' + arrDate[1] + '/' + arrDate[0] + ' ' + (arr[1] !== "00:00:00" ? arr[1] : "");
      }
    }
  }
};
</script>