<template>
  <div id="reviews" class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ this.name }}</h3>
    </div>
    <div class="card-body">
      <div id="hotelSearchForm" class="form">

        <div class="row mb-4">
          <div class="col-lg-3 mt-2">
            <label>{{ $t('Hotel', {value: 'Hotel'}) }}</label>
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   id="hotel"
                   name="hotel"
                   :placeholder="$t('Hotel name', {value: 'Hotel name'})"
                   maxlength="100"
                   value=""
                   required="required"
                   aria-required="true"
                   :data-msg-required="$t('Please select a hotel.', {value: 'Please select a hotel.'})"
                   :data-autocomplete="this.$envConfig.API_URL + '/autocomplete/accommodation'"
                   data-autocomplete-type="autocomplete"
                   data-autocomplete-method="POST"
                   data-autocomplete-response-data="accommodations"
                   data-autocomplete-columns="id,name"
                   data-autocomplete-label="#name#"
                   data-autocomplete-value="#id#"
                   data-autocomplete-target="#hotelId"
                   data-autocomplete-limit="10"
                   :data-autocomplete-message="$t('No matches found.', {value: 'No matches found.'})"
                   data-autocomplete-exclude-field="id"
                   data-autocomplete-exclude=""
            />
            <input type="hidden"
                   class="form-control m-input "
                   autocomplete="off"
                   id="hotelId"
                   name="hotelId"
                   data-search-key="hotelId"
                   data-search-type="="
                   data-type="number"
                   placeholder=""
                   value=""/>
          </div>
          <div class="col-lg-3 mt-2">
            <label>{{ $t('Channel', {value: 'Channel'}) }}</label>
            <select data-search-key="channel"
                    name="channel"
                    id="show_channel"
                    data-search-type="="
                    data-select="true"
                    :data-select-params="this.allChannelsText"
                    data-search-prop-key="true"
                    class="form-control m-input col-12">
              <option value="">{{ $t('All Channels', {value: 'All Channels'}) }}</option>
              <option v-for="channel in channels" :value="channel.name" :key="channel.id">
                {{ channel.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 mt-2">
            <label>{{ $t('Review Date', {value: 'Review Date'}) }}</label>
            <div class="input-daterange input-group" id="m_datepicker">
              <input type="text" autocomplete="off" class="form-control m-input"
                     :placeholder="$t('After', {value: 'After'})"
                     name="fromDate"
                     id="fromDate"
                     data-type="date"
                     data-date-to-date="#toDate"
                     data-search-key="fromDate"
                     data-search-type="="
              >
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input type="text" autocomplete="off" class="form-control m-input"
                     :placeholder="$t('Before', {value: 'Before'})"
                     name="toDate"
                     id="toDate"
                     data-type="date"
                     data-date-from-date="#fromDate"
                     data-search-key="toDate"
                     data-search-type="="
              >
            </div>
          </div>
          <div class="col-lg-3 mt-2">
            <label>{{ $t('Rates', {value: 'Rates'}) }}</label>
            <select data-search-key="reviewScore"
                    name="reviewScore"
                    id="show_rates"
                    data-search-type="="
                    data-select="true"
                    :data-select-params="this.allRatesText"
                    data-search-prop-key="true"
                    class="form-control m-input col-12">
              <option value="">{{ $t('All Rates', {value: 'All Rates'}) }}</option>
              <option v-for="rate in rates" :value="rate.value" :key="rate.value">
                {{ rate.display }}
              </option>
            </select>
          </div>
          <div class="col-lg-3 mt-2 align-text-bottom lh-90">
            <button class="btn btn-secondary"
                    name="searchBtn"
                    id="show_searchBtn"
                    data-button-type="search"
            >
              <i class="la la-search"></i>
              {{ $t('Search', {value: 'Search'}) }}
            </button>

          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-striped table-bordered table-hover table-checkable"
             data-table-type="datatable"
             :data-method-url="this.$envConfig.API_URL + '/review/list'"
             data-search-form="#hotelSearchForm"
             data-table-default-sort-field="6"
             data-table-default-sort-type="desc"
             id="dataTableReviews">
        <thead>
        <tr>
          <th class="text-wrap-th">{{ $t('Review', {value: 'Review'}) }}</th>
          <th>{{ $t('Guest', {value: 'Guest'}) }}</th>
          <th >{{ $t('Score', {value: 'Score'}) }}</th>
          <th>{{ $t('Language', {value: 'Language'}) }}</th>
          <th>{{ $t('Hotel', {value: 'Hotel'}) }}</th>
          <th>{{ $t('Channel', {value: 'Channel'}) }}</th>
          <th>{{ $t('Date', {value: 'Date'}) }}</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tfoot>
        <tr id="reference" data-table-row-id="id" class="hidden">
          <td class="text-wrap-td">
            <div class='text-wrap-div'>##review##</div>
          </td>
          <td>##reviewer##</td>
          <td>##reviewScore##</td>
          <td>##language##</td>
          <td>##hotel.name##</td>
          <td>##channel##</td>
          <td data-table-render-type="date">##reviewDate##</td>
          <td class="nowrap">
            <a href="javascript:void(0)" data-vue-click="showReviewModal" data-review-id="##id##" class="p-3">
              <i class="far fa-edit"></i>
            </a>
            <a href="##reviewUrl##" target="_blank" class="p-3">
              <i class="fas fa-external-link-alt"></i>
            </a>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
    <review-detail
        v-show="isModalVisible"
        @close="closeModal"
        :review="showReview"
    />
  </div>
</template>
<style>
@media (max-width: 576px) {
  th.text-wrap-th,
  td.text-wrap-td {
    max-width: 200px;
    max-height: 100px;
  }
  .text-wrap-div {
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    max-height: 100px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  th.text-wrap-th,
  td.text-wrap-td {
    max-width: 250px;
    max-height: 100px;
  }
  .text-wrap-div {
    text-overflow: ellipsis;
    width: 250px;
    overflow: hidden;
    max-height: 100px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  th.text-wrap-th,
  td.text-wrap-td {
    max-width: 350px;
    max-height: 150px;
  }
  .text-wrap-div {
    text-overflow: ellipsis;
    width: 350px;
    overflow: hidden;
    max-height: 150px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  th.text-wrap-th,
  td.text-wrap-td {
    max-width: 350px;
    max-height: 200px;
  }
  .text-wrap-div {
    text-overflow: ellipsis;
    width: 350px;
    overflow: hidden;
    max-height: 200px;
  }
}
@media (min-width: 1200px) {
  th.text-wrap-th,
  td.text-wrap-td {
    width: 450px !important;
    max-width: 450px  !important;
    max-height: 200px  !important;
  }
  .text-wrap-div {
    text-overflow: ellipsis;
    width: 450px;
    overflow: hidden;
    max-height: 200px;
  }
}
</style>
<script>
import $ from "jquery";
import {coreJs} from "utilPath/coreJs";
import {_elementUtil} from "utilPath/element-util";
import reviewDetail from "./reviews/detail";

export default {
  name: 'Reviews',
  components: {reviewDetail},
  data() {
    return {
      name: this.$t('Reviews', {value: "Reviews"}),
      title: this.$t('Reviews', {value: "Reviews"}),
      allRatesText: "{\"allowClear\": true, \"placeholder\":\""+ this.$t('All Rates', {value: 'All Rates'}) +"\",\"minimumResultsForSearch\": -1}",
      allChannelsText: "{\"allowClear\": true, \"placeholder\":\""+ this.$t('All Channels', {value: 'All Channels'}) +"\",\"minimumResultsForSearch\": -1}",
      channels: [],
      rates: [
        {
          value: 'Terrible',
          display: '0-2'
        },
        {
          value: 'Bad',
          display: '2-4'
        },
        {
          value: 'Neutral',
          display: '4-6'
        },
        {
          value: 'Good',
          display: '6-8'
        },
        {
          value: 'Excellent',
          display: '8-10'
        }],
      isModalVisible: false,
      showReview: {
        id: null
      },
    }
  },
  mounted() {
    coreJs.initContainer({container: '#reviews', name: this.name, title: this.title});
    this.getChannels();

    $(document).on('click', '[data-vue-click="showReviewModal"]', (evt) => {
      let reviewId = $(evt.target).parent('a').attr('data-review-id');
      this.showModal(reviewId);
    });
  },
  methods: {
    getChannels() {
      let module = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/channel/list', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({}),
        callBack: function (elt, response) {
          module.channels = response.data.channels;
        }
      });
    },
    showModal(reviewId) {
      let _this = this;
      _this.showReview.id = null;
      _this.isModalVisible = true;

      _elementUtil.getService(this.$envConfig.API_URL + '/review/' + reviewId, {
        element: null,
        callBack: function (elt, response) {
          _this.setReviewModal(response);
        }
      });

    },
    closeModal() {
      this.isModalVisible = false;
    },
    setReviewModal(response) {
      this.showReview = response.data.review;

    },
    selectValue() {
      return "{\"allowClear\": true, \"placeholder\":\""+ $t('All Rates', {value: 'All Rates'}) +"\",\"minimumResultsForSearch\": -1}";
    }
  }
};
</script>